import React, { useState } from "react";
import { IProfile, IUser } from "../../../interfaces/IProfile";
import { IReservation } from "../../../interfaces/IReservation";
import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, IconButton, LinearProgress, MenuItem, TableCell, TableRow } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { PersonEditor } from "../Person/PersonEditor";
import { IProfileAgeGroup } from "../../../interfaces/IProfileAgeGroup";
import { IProfileEatingType } from "../../../interfaces/IProfileEatingType";
import { uploadFetch } from "../../../hooks/useFetch";

interface IProps {
    profileObject: IProfile;
    currentProfile: IProfile;
    editReservation: IReservation;
    setEditReservation: Function;
    relatedProfileArray: IProfile[];
    setRelatedProfileArray: Function;
    profileAgeArray: IProfileAgeGroup[];
    profileEatingArray: IProfileEatingType[];
}

export const ReservationProfileRow:React.FC<IProps> = ({
    profileObject,
    currentProfile, 
    editReservation, setEditReservation,
    relatedProfileArray, setRelatedProfileArray,
    profileAgeArray, profileEatingArray
}) => { 
    const [editProfile, setEditProfile] = useState(currentProfile);
    const [editUser, setEditUser] = useState({...currentProfile.User,username: ""} as IUser);
    //
    const [isOpenEdit,setIsOpenEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfully,setWasSuccessfully] = useState(true);


    const deleteFromReservation = () => {
        setEditReservation({
            ...editReservation,
            ProfileArray: editReservation.ProfileArray.filter(x => x !== currentProfile.id)
        })
    }


    const handleEdit = () => {
        setEditProfile(currentProfile);
        setEditUser({...currentProfile.User,username: ""});
        setIsOpenEdit(true);
    }

    const handleAfterUpdate = (updatedProfile:IProfile) => {
        setRelatedProfileArray([
            ...relatedProfileArray.map(x=> x.id === updatedProfile.id ? updatedProfile : x)
        ])
        setIsOpenEdit(false)
    }

    const handleUpdate = () => {
        uploadFetch(`/user/profile/update/${currentProfile.id}`,false,{...editProfile, User: editUser},handleAfterUpdate,setWasSuccessfully,setIsLoading)
    }
    

    return(
        <>
            <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)}>
                <DialogContent>
                    <Collapse in={isLoading}>
                        <LinearProgress/>
                    </Collapse>
                    <Collapse in={!wasSuccessfully}>
                        <Alert severity="error">
                            Es ist ein Fehler aufgetretten!
                            <br/>Versuche es später bitte erneut. Bleibt der Fehler bestehen, dann schreibe uns bitte eine <a href="mailto:pia-und-daniel@kmiotek.de">E-Mail </a>.
                        </Alert>
                    </Collapse>
                    <PersonEditor
                        key={`profile-edit-${currentProfile.id}`}
                        editProfile={editProfile}
                        editUser={editUser}
                        setEditProfile={setEditProfile}
                        setEditUser={setEditUser}
                        profileAgeArray={profileAgeArray}
                        profileEatingArray={profileEatingArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} variant="outlined" onClick={() => setIsOpenEdit(false)}>Abbruch</Button>
                    <Button disabled={isLoading} variant="contained" onClick={handleUpdate}>Speichern</Button>
                </DialogActions>
            </Dialog>

            <TableRow>
                <TableCell>
                    {
                        (editProfile.id === profileObject.id) 
                        ? "Ich"
                        :(currentProfile.User.first_name !== "" && currentProfile.User.last_name !== "")
                        ? `${currentProfile.User.first_name} ${currentProfile.User.last_name}`
                        : `${currentProfile.User.username}`
                    }
                </TableCell>
                <TableCell>
                    {(editProfile.id !== profileObject.id) &&
                        <>
                            { (currentProfile.User.username === "" || currentProfile.User.username.split("@").length === 1) &&
                                <IconButton size="small" onClick={handleEdit}><Edit/></IconButton>
                            }
                            <IconButton size="small" onClick={deleteFromReservation}><Delete/></IconButton>
                        </>
                        
                    }
                </TableCell>
            </TableRow>
        </>
    )
}