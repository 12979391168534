import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


export const Autologin:React.FC = () => {
    const params = useParams();
    const navigation = useNavigate();

    useEffect(() => {
        if (params.token !== undefined) {
            localStorage.setItem('SessionToken_Wedding',params.token)
        }
        navigation("/guest")
        window.location.reload();
    },[params])

    return(
        <>
            Bitte warten....
        </>
    )
}