import { Cake, Casino, Dining, Favorite, Flatware, LocalBar, LocalDrink, Luggage, MonochromePhotos, Nightlife, NoEncryptionGmailerrorredOutlined, SportsBar, WavingHand, WineBar } from "@mui/icons-material";
import { Box, Grid2, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";


export const Programm:React.FC = () => {
    return(
            <Box sx={{mt: 10}}>
                <Box sx={{backgroundColor: "#d1bc8a", width: "100%", color: "#fff", pt: 3, pb: 10}}>
                <Box sx={{pl: {xs: 1, md: 15}, pr: {xs: 1, md: 15}}}>
                    <Typography variant="h4" sx={{mb: 2, flexGrow: 1, textAlign: "center"}}>ABLAUF</Typography>
                    <Grid2 container spacing={2}>


                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Typography variant="h6" >Freitag</Typography>
                            <Typography variant="caption" sx={{mt: -1, mb: 2}}>Optional</Typography>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{width: 20, color: "#fff"}}><Luggage/></TableCell>
                                        <TableCell sx={{width: 120, color: "#fff"}}>Ab 15:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Check-In</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><Dining/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>19:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Gemeinsames Abendessen</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><SportsBar/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>20:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Gemütliches Beisammensein</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{color: "#fff"}}>
                                            <Typography variant="caption">
                                                Bitte habt Verständnis, dass wir die Kosten für den Freitag nicht tragen können.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Grid2>


                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Typography variant="h6" sx={{mb: 2}}>Samstag</Typography>
                            <Table sx={{mt: 3}} size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{width: 20, color: "#fff"}}><WavingHand/></TableCell>
                                        <TableCell sx={{width: 120, color: "#fff"}}>14:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Empfang der Gäste</TableCell>
                                    </TableRow>

                                    <TableRow >
                                        <TableCell sx={{color: "#fff"}}><Favorite/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>14:30 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Freie Trauung</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><LocalBar/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>15:15 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Sektempfang</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><Cake/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>16:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Kaffee und Kuchen</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><MonochromePhotos/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>18:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Saaleröffnung und Gruppenfoto</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><Flatware/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>19:00 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Dinner</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><Casino/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>20:30 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Spiel und Spaß</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell sx={{color: "#fff"}}><Nightlife/></TableCell>
                                        <TableCell sx={{color: "#fff"}}>22:30 Uhr</TableCell>
                                        <TableCell sx={{color: "#fff"}}>Eröffnung der Tanzfläche</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 5 }}>

                        </Grid2>
                        <Grid2 size={{ xs: 0, md: 1 }}/>

                        <Grid2 size={{ xs: 12, md: 5 }}>
                            

                        </Grid2>
                    </Grid2>
                </Box>
                </Box>
            </Box>
    )
}