import { Avatar, Box, Button, CssBaseline, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";



export const AboutOus:React.FC = () =>{
    const navigation = useNavigate();

    return(
        <div >




            <CssBaseline/>
            <Box sx={{mt: 10, m: 3, ml: {xs: 1, md: 15}, mr: {xs: 1, md: 15}}}>
                <Typography variant="h4" sx={{mb: 2, flexGrow: 1, textAlign: "center"}}>ÜBER UNS</Typography>
                <Typography sx={{float: "center", textAlign: "center"}}>
                Liebe Familie und Freunde,
                <p/>
                nun sind wir bereits seit vier Jahren ein Paar und wohnen fast genau so lange zusammen. Nach dieser Probezeit sind wir uns sicher. Wir wollen den nächsten Schritt wagen und uns das Ja-Wort geben. Und weil uns Familie und Freunde schon immer besonders wichtig waren, freuen wir uns riesig, diesen besonderen Moment mit euch zu teilen. Wir wollen gemeinsam die Liebe zelebrieren und unvergessliche Erinnerungen schaffen!
                Wir danken allen, die uns bei der Realisierung unserer Hochzeit helfen und helfen werden, insbesondere unseren Trauzeugen, und sind dankbar, dass Ihr uns auf diesem Weg mit eurer Unterstützung und Liebe begleitet!
                <p/>
                <p/>
                <br/>Pia & Daniel
                </Typography>
                <Box sx={{ ml: 3, flexGrow: 1,
                    display: { xs: 'flex', md: 'flex' },             
                    justifyContent: "center", // Horizontale Zentrierung
                    alignItems: "center",      // Vertikale Zentrierung
                    textAlign: "center", }}
                >
                    <Avatar sx={{mt: 5, width: 100, height: 100}} alt="Pia und Daniel Kmiotek" src="/avatar_1.jpg" />
                </Box>
                
            </Box>
            {/*
            <Parallax speed={50} style={{zIndex: 10000}}>
                <div style={{marginLeft: 400, height: 0, zIndex: 10000}} className="heart"/>
            </Parallax>
            <Parallax speed={20}>
                <div style={{marginLeft: 30, height: 0}} className="heart"/>
            </Parallax>
            <Parallax speed={10}>
                <div style={{marginLeft: 100, height: 0}} className="heart"/>
            </Parallax>
            <Parallax speed={15}>
                <div style={{marginLeft: 200, height: 0}} className="heart"/>
            </Parallax>
            <Parallax speed={70}>
                <div style={{marginLeft: 300, height: 0}} className="heart"/>
            </Parallax>
            <Parallax speed={5}>
                <div style={{marginLeft: 70, height: 0}} className="heart"/>
            </Parallax>
            */}

        </div>
    )
} 