import React, { useEffect, useState } from "react";
import { IProfile } from "../../../interfaces/IProfile";
import { IReservation } from "../../../interfaces/IReservation";
import { Box, Button, Card, CardContent, Collapse, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { ReservationProfileRow } from "./ReservationProfileRow";
import { upload } from "@testing-library/user-event/dist/upload";
import { uploadFetch } from "../../../hooks/useFetch";
import { PersonEditor } from "../Person/PersonEditor";
import { IProfileAgeGroup } from "../../../interfaces/IProfileAgeGroup";
import { IProfileEatingType } from "../../../interfaces/IProfileEatingType";


interface IProps {
    profileObject: IProfile;
    editReservation: IReservation;
    setEditReservation: Function;
    relatedProfileArray: IProfile[];
    setRelatedProfileArray: Function;

    profileAgeArray: IProfileAgeGroup[];
    profileEatingArray: IProfileEatingType[];
}


const getEmptyProfile = () => {
    let returnObject:IProfile = {
        id: -1,
        notes: "",
        ProfileAgeGroup: 1,
        ProfileEatingType: 1,
        User: {
            first_name: "",
            is_superuser: false,
            last_name: "",
            username: ""
        }
    }
    return returnObject
}



export const ReservationEdit:React.FC<IProps> = ({
    profileObject,
    editReservation, setEditReservation,
    relatedProfileArray, setRelatedProfileArray,
    profileAgeArray, profileEatingArray
}) => {
    const [isOpenAddPerson, setIsOpenAddPerson] = useState(false);
    const [isOpenAddEmail, setIsOpenAddEmail] = useState(false);
    const [isOpenAddData, setIsOpenAddData] = useState(false);
    //
    const [addEmail, setAddEmail] = useState("");
    //
    const [addProfile, setAddProfile] = useState(getEmptyProfile());
    const [addUser,setAddUser] = useState(getEmptyProfile().User)

    useEffect(() => {
        console.log("editReservation")
        console.log(editReservation)
    },[editReservation])
    useEffect(() => {
        console.log("relatedProfileArray")
        console.log(relatedProfileArray)
    },[relatedProfileArray])

    const [isLoadingAdd, setIsLoadingAdd] = useState(false);



    const handleAddEmail = () => {
        setAddEmail("");
        setIsOpenAddEmail(true);
        setIsOpenAddPerson(false);
    }

    const handleAfterSubmit = (addedProfile:IProfile) => {
        setEditReservation({
            ...editReservation,
            ProfileArray: [...editReservation.ProfileArray, addedProfile.id]
        })
        setRelatedProfileArray([
            ...relatedProfileArray,
            addedProfile
        ])
        setIsOpenAddEmail(false);
        setIsOpenAddData(false);
    }

    const handleSubmitAddEmail = (e: React.SyntheticEvent)  => {
        e.preventDefault();
        uploadFetch("/user/profile/add_email/",true,{"username" : addEmail},handleAfterSubmit,() => {},setIsLoadingAdd)

    }



    const handleAddData = () => {
        setAddProfile(getEmptyProfile());
        setAddUser(getEmptyProfile().User)
        setIsOpenAddData(true);
        setIsOpenAddPerson(false);
    }

    const handleSubmitAddData = (e: React.SyntheticEvent)  => {
        e.preventDefault();
        uploadFetch("/user/profile/add_data/",true,{...addProfile, User: addUser},handleAfterSubmit,() => {},setIsLoadingAdd)
    }


    return(
        <>
            <Dialog open={isOpenAddData} onClose={() => setIsOpenAddData(false)}>
                <DialogContent>
                    <form onSubmit={handleSubmitAddData} autoComplete="on">
                            {(isLoadingAdd) && <Box sx={{mb: 3}}><LinearProgress/></Box>}
                            <PersonEditor
                                key={`person-add-editor`}
                                editProfile={addProfile}
                                setEditProfile={setAddProfile}
                                editUser={addUser}
                                setEditUser={setAddUser}
                                profileAgeArray={profileAgeArray}
                                profileEatingArray={profileEatingArray}
                            />
                            

                            
                            <Button sx={{mt: 3, float: "right"}}  disabled={isLoadingAdd} variant="contained" color="primary" type="submit">Hinzufügen</Button>
                            <Button sx={{mt: 3, float: "right", mr: 2}}  disabled={isLoadingAdd} variant="outlined" color="primary" onClick={() => setIsOpenAddData(false)}>Abbruch</Button>
                    </form>

                </DialogContent>
            </Dialog>


            <Dialog open={isOpenAddEmail} onClose={() => setIsOpenAddEmail(false)}>
                <DialogContent>
                    <form onSubmit={handleSubmitAddEmail} autoComplete="on">
                            {(isLoadingAdd) && <Box sx={{mb: 3}}><LinearProgress/></Box>}
                            <TextField
                                label="E-Mail"
                                value={addEmail}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAddEmail(String(event.target.value))}
                                required
                                type="email"
                            />
                            
                            <Button sx={{mt: 3, float: "right"}}  disabled={isLoadingAdd} variant="contained" color="primary" type="submit">Hinzufügen</Button>
                            <Button sx={{mt: 3, float: "right", mr: 2}}  disabled={isLoadingAdd} variant="outlined" color="primary" onClick={() => setIsOpenAddEmail(false)}>Abbruch</Button>
                    </form>

                </DialogContent>
            </Dialog>


            <Dialog open={isOpenAddPerson} onClose={() => setIsOpenAddPerson(true)}>
                <DialogTitle>Wie wollen Sie die Person hinzufügen?</DialogTitle>
                <DialogContent>
                    <Grid  container spacing={2}>
                        <Grid item xs={6}>
                            <Card onClick={handleAddEmail} sx={{cursor: "pointer"}}> 
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        E-Mail
                                    </Typography>
                                    <Typography variant="body2">
                                        Die Person erhält eine Einladung und ärgenzt die personenbezogene Informationen selber 
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card onClick={handleAddData} sx={{cursor: "pointer"}}> 
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Erfassen
                                    </Typography>
                                    <Typography variant="body2">
                                        Du erfässt alle personenbezogene Informationen der Person (bspw. bei Kindern).
                                        <br/>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Typography variant="h6" sx={{mb: 1, mt: 3}}>Freitag</Typography>
            <Grid container spacing={2}>
                <Grid item xs={editReservation.confirmed_friday ? 6 : 12}>
                    <CustomeTextField
                        type="boolean"
                        attr="confirmed_friday"
                        object={editReservation}
                        setObject={(updated:IReservation) => setEditReservation({...updated, hotel_friday: false})}
                        label="Teilnahme"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Collapse in={editReservation.confirmed_friday}>
                        <CustomeTextField
                            type="boolean"
                            attr="hotel_friday"
                            object={editReservation}
                            setObject={setEditReservation}
                            label="Hotel"
                        />
                    </Collapse>
                </Grid>
            </Grid>

            <Typography variant="h6" sx={{mb: 1, mt: 3}}>Samstag</Typography>
            <Grid container spacing={2}>
                <Grid item xs={editReservation.confirmed_saturday ? 6 : 12}>
                    <CustomeTextField
                        type="boolean"
                        attr="confirmed_saturday"
                        object={editReservation}
                        setObject={(updated:IReservation) => setEditReservation({...updated, hotel_saturday: false})}
                        label="Teilnahme"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Collapse in={editReservation.confirmed_saturday}>
                        <CustomeTextField
                            type="boolean"
                            attr="hotel_saturday"
                            object={editReservation}
                            setObject={setEditReservation}
                            label="Hotel"
                        />
                    </Collapse>
                </Grid>
            </Grid>


            <Typography variant="h6" sx={{mb: 1, mt: 5}}>
                Personen dieser Anmeldung
                <Button sx={{float: "right"}} variant="contained" onClick={() => setIsOpenAddPerson(true)}>Person hinzufügen</Button>
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell sx={{width: 100}}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { relatedProfileArray
                        .filter(currentProfile => editReservation.ProfileArray.indexOf(currentProfile.id) > -1)
                        .map(currentProfile =>
                            <ReservationProfileRow
                                key={`current-profile-row-${currentProfile.id}`}
                                profileObject={profileObject}
                                currentProfile={currentProfile}
                                editReservation={editReservation}
                                setEditReservation={setEditReservation}
                                relatedProfileArray={relatedProfileArray}
                                setRelatedProfileArray={setRelatedProfileArray}
                                profileAgeArray={profileAgeArray}
                                profileEatingArray={profileEatingArray}
                            />
                        )
                    }
                </TableBody>
            </Table>
            
        </>
    )
}