import { Alert, Box, Button, Grid, LinearProgress, TextField } from "@mui/material";
import React, { useState } from "react";

interface IProps {
    msg: string | null;
}

export const Login:React.FC<IProps> = (props) => {
    const [msg,setMsg] = useState(props.msg);
    //
    const [currentPage,setCurrentPage] = useState(0);
    //
    const [email, setEmail] = useState<string>("");
    const [isLoading,setIsLoading] = useState(false);

    const handleSubmitLogin = (e: React.SyntheticEvent) => {
        e.preventDefault();
        tryToLogin();
    }


    const tryToLogin = () => {
        setIsLoading(true);
        fetch("/api/user/login/",{
            method: "POST",
            headers: { 
                'Content-Type': 'application/json'
                
            },
            body: JSON.stringify({"username" : email})
        })
        .then(res => {
            if (res.status == 200) {
                setCurrentPage(1);
            } else {throw Error(String(res.status))}
            return undefined;
        })
        .catch( error => {
            if (String(error).includes("401")) {
                setMsg("Ihre Session ist abgelaufen.")
            } else if (String(error).includes("404")) {
                setMsg("Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort");
            } else if (String(error).includes("403")) {
                setMsg(`Ihr Konto wurde deaktiviert!`);
            } else {
                setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
            }
      })
      .finally(() => {
        setIsLoading(false)
      })
    }


    if (currentPage === 0) {
        return(
            <>
                 <form onSubmit={handleSubmitLogin} autoComplete="on">
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: '100vh'}}
                    >
                        <Grid item xs={3} style={{minWidth: 300}}>
                            <Alert severity="info" sx={{mb: 5}}>
                                Sofern du noch kein Konto hast, wird automatisch ein Konto mit der hier angegebenen E-Mail-Adresse erstellt, sobald du auf Anmelden klickst.
                                <br/>Solltest du bei der Anmeldung keine E-Mail erhalten haben, prüfe bitte dein SPAM-Ordner.
                            </Alert>
                        </Grid>
                        <Grid item xs={3} style={{minWidth: 300}}>
                            {(isLoading) && <Box sx={{mb: 2}}><LinearProgress/></Box>}
                        </Grid>
                        <Grid item xs={3} style={{minWidth: 300}}>
                            <TextField
                                label="E-Mail"
                                value={email}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(String(event.target.value))}
                                required
                                type="email"
                            />
                        </Grid>
                        <Box sx={{mt: 3}}/>
                        <Grid item xs={3} style={{minWidth: 300}}>
                            <div style={{float: 'right'}}>
                                <Button disabled={isLoading} size="small" variant="contained" color="primary" type="submit">Anmelden</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
    if (currentPage === 1) {
        return(
            <>
                <Box sx={{m: 10}}>
                    <Alert severity="success">
                        Du erhälst in wenigen Minuten eine E-Mail mit dem Anmeldelink.
                    </Alert>
                </Box>
            </>
        )
     }
    else {
        return(
            <>
                
                Fehler1!
            </>
        )
    }
} 