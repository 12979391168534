import React from "react";
import { useFetch } from "../../hooks/useFetch";
import { IProfile } from "../../interfaces/IProfile";
import { IProfileAgeGroup } from "../../interfaces/IProfileAgeGroup";
import { IProfileEatingType } from "../../interfaces/IProfileEatingType";
import { NewAtPortal } from "./NewAtPortal";
import { IReservation } from "../../interfaces/IReservation";
import { ReservationMain } from "./Reservation/ReservationMain";
import { Alert, Box } from "@mui/material";


export const GuestMain:React.FC = () => {
    const [profileObject, setProfileObject, wasSuccessfullyProfileObject] = useFetch<IProfile>("/user/validate/");
    const [profileAgeArray, serprofileAgeArray, wasSuccessfullyProfileAgeArray] = useFetch<IProfileAgeGroup[]>("/user/profile/age_group/");
    const [profileEatingArray, setprofileEatingArray, wasSuccessfullyProfileEatingArray] = useFetch<IProfileEatingType[]>("/user/profile/eating_type/");
    //
    const [reservationArray, setReservationArray, wasSuccessfullyreservationArray] = useFetch<IReservation[]>("/reservation/byUser/");
    const [relatedProfileArray, setrelatedProfileArray, wasSuccessfullyRelatedProfileArray] = useFetch<IProfile[]>("/reservation/relatedProfiles/");


    if (
        !wasSuccessfullyProfileObject 
        || !wasSuccessfullyProfileAgeArray 
        || !wasSuccessfullyProfileEatingArray 
        || !wasSuccessfullyreservationArray
        || !wasSuccessfullyRelatedProfileArray
    )  {
        return <>Fehler!</>
    }
    else if (
        profileObject === undefined 
        || profileAgeArray === undefined 
        || profileEatingArray === undefined 
        || reservationArray === undefined
        || relatedProfileArray === undefined
    ) {
        return <>Bitte warten...</>
    }
    else {
        return(
            <>
                <Alert severity="info">
                    Du kannst bis zum 31.12.2024 deine Anmeldung anlegen und verändern.
                    <br/>Weitere Funktionen, wie bspw. die Anmeldung für Kinderaktivitäten, werden am 01.12. freigeschaltet.
                </Alert>
                <Box sx={{mt: 5}}/>
                <NewAtPortal
                    profileObject={profileObject}
                    setProfileObject={setProfileObject}
                    profileAgeArray={profileAgeArray}
                    profileEatingArray={profileEatingArray}
                />
                
                <ReservationMain
                    profileObject={profileObject}
                    reservationArray={reservationArray}
                    setReservationArray={setReservationArray}
                    relatedProfileArray={[profileObject, ...relatedProfileArray.filter(x => x.id !== profileObject.id)]}
                    setRelatedProfileArray={setrelatedProfileArray}
                    profileAgeArray={profileAgeArray}
                    profileEatingArray={profileEatingArray}
                />
            </>
        )
    }
}