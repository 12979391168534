import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Main } from './components/Main';
import { Navbar } from './components/core/Navbar';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { SecurityDriver } from './components/Guest/Security/SecurityDriver';
import { Nvarbar2 } from './components/core/Navbar2';
import { Autologin } from './components/core/Autologin';
import { IProfile } from './interfaces/IProfile';
import { GuestMain } from './components/Guest/GuestMain';

function App() {
  const [isLoading,setIsLoading] = useState(true);
  const [isLogged,setIsLogged] = useState(false);
  const [msg, setMsg] = useState<string | null>(null);



  const [theme,setTheme] = useState(createTheme({
    typography: {
      fontFamily: "Playfair Display",
    },
    palette: {
      primary: {
        main: '#8e7b50',
      },
      secondary: {
        main: '#8e7b50',
      },
      text: {
        primary: '#8e7b50',
      },
    },
  }));


  useEffect(() => {
    let sessionToken = localStorage.getItem('SessionToken_Wedding');

    if (sessionToken === "" || sessionToken === null) {
        setIsLogged(false);
        setIsLoading(false);
    } else {
        fetch("/api/user/validate/",{
            method: "GET",
            headers : { "Authorization" : "Token " + localStorage.getItem('SessionToken_Wedding') }
        })
        .then(res => {
            if (res.status == 200) {
                setIsLogged(true);
                setIsLoading(false);
                return res.json()
            } else {throw Error(String(res.status))}
            return undefined;
        })
        .then(res => {
          let currentProfile:IProfile = res;
          localStorage.setItem("first_name_wedding",currentProfile.User.first_name)
          console.log(res)
        })
        .catch( error => {
            if (String(error).includes("401")) {
                setMsg("Ihre Session ist abgelaufen.")
            } else if (String(error).includes("404")) {
                setMsg("Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort");
            } else if (String(error).includes("403")) {
                setMsg(`Ihr Konto wurde deaktiviert!`);
            } else {
                setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
            }
            setIsLogged(false);
            setIsLoading(false);
      })
    }
},[])


  const securityDriver = (children:React.ReactElement) => {
    return(
      <>
        <SecurityDriver msg={msg} setMsg={setMsg} isLoading={isLoading} setIsLoading={setIsLoading} isLogged={isLogged} setIsLogged={setIsLogged}>
          {children}
        </SecurityDriver>
      </>
    )
  }

  //d1bc8a
  return (
    <ThemeProvider theme={theme}>   
      <ParallaxProvider>
          
            <Router>
            <Nvarbar2 isLogged={isLogged}>
                <Routes>
                  <Route path="/" element={<Main />}/>

                  <Route path="/auto_login/:token" element={<Autologin/>} />
                  <Route path="/guest" element={securityDriver(<GuestMain/>) }/>
                  
                   
                  
                </Routes>
            

            </Nvarbar2>
            </Router>
          
        </ParallaxProvider>
    </ThemeProvider>
  );
}

export default App;
