import React from "react";
import { IProfile, IUser } from "../../../interfaces/IProfile";
import { IProfileAgeGroup } from "../../../interfaces/IProfileAgeGroup";
import { IProfileEatingType } from "../../../interfaces/IProfileEatingType";
import { Alert, Grid, MenuItem, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";

interface IProps {
    editProfile: IProfile;
    setEditProfile: Function;
    editUser: IUser;
    setEditUser: Function;
    profileAgeArray: IProfileAgeGroup[];
    profileEatingArray: IProfileEatingType[];
    disableEMail?: boolean;
}


export const PersonEditor:React.FC<IProps> = ({
    editProfile, setEditProfile,
    editUser, setEditUser,
    profileAgeArray, profileEatingArray, disableEMail
}) => {
    return(
        <>
            <Typography sx={{mt: 3, mb: 1}} variant="h6">Grundinformation</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CustomeTextField
                        type="string"
                        attr="first_name"
                        label="Vorname"
                        object={editUser}
                        setObject={setEditUser}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomeTextField
                        type="string"
                        attr="last_name"
                        label="Nachname"
                        object={editUser}
                        setObject={setEditUser}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomeTextField
                        type="select"
                        attr="ProfileAgeGroup"
                        label="Altersbereich"
                        object={editProfile}
                        setObject={setEditProfile}
                        required
                    >
                        {profileAgeArray.map(x =>
                            <MenuItem
                                key={`ProfileAgeGroup-seect-${x.id}`}
                                value={x.id}
                            >
                                {x.profileAgeGroup}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
            </Grid>

            <Typography sx={{mt: 3, mb: 1}} variant="h6">Essen und Unverträglichkeiten</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomeTextField
                        type="select"
                        attr="ProfileEatingType"
                        label="Essensgewohnheiten"
                        object={editProfile}
                        setObject={setEditProfile}
                        required
                    >
                        {profileEatingArray.map(x =>
                            <MenuItem
                                key={`profileEatingArray-seect-${x.id}`}
                                value={x.id}
                            >
                                {x.profileEatingType}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item xs={12}>
                    <CustomeTextField
                        type="string"
                        attr="notes"
                        label="Allergien, Unverträglichkeiten etc."
                        object={editProfile}
                        setObject={setEditProfile}
                        rows={3}
                    />
                </Grid>
            </Grid>

            {(disableEMail === undefined || disableEMail === false) &&
            <>
                <Typography sx={{mt: 3, mb: 1}} variant="h6">E-Mail und Zugang</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Wenn du eine E-Mail-Adresse hinzufügst, erhält diese Person automatisch einen Zugang zum Gästeportal und eine Einladung zu deiner Reservierung.
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomeTextField
                            type="email"
                            attr="username"
                            label="E-Mail"
                            object={editUser}
                            setObject={setEditUser}
                        />
                    </Grid>
                </Grid>
            </>
            }
        </>
    )
}