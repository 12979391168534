import React, { useState } from "react";
import { IProfile } from "../../../interfaces/IProfile";
import { IReservation } from "../../../interfaces/IReservation";
import { Alert, Box, Button, Card, CardContent, Collapse, Dialog, DialogActions, DialogContent, Grid, LinearProgress, Typography } from "@mui/material";
import { ReservationEdit } from "./ReservationEdit";
import { IProfileAgeGroup } from "../../../interfaces/IProfileAgeGroup";
import { IProfileEatingType } from "../../../interfaces/IProfileEatingType";
import { uploadFetch } from "../../../hooks/useFetch";
import { Hotel } from "@mui/icons-material";


interface IProps {
    profileObject: IProfile;
    reservationArray: IReservation[];
    setReservationArray: Function;
    relatedProfileArray: IProfile[];
    setRelatedProfileArray: Function;

    profileAgeArray: IProfileAgeGroup[];
    profileEatingArray: IProfileEatingType[];
}


const getEmptyReservation = (currentProfileId:number) => {
    let newReservation:IReservation = {
        id: -1,
        confirmed_friday: false, 
        confirmed_saturday: false,
        hotel_friday: false,
        hotel_saturday: false,
        ProfileArray: [currentProfileId]
    }
    return newReservation;
}


export const ReservationMain:React.FC<IProps> = ({
    profileObject,
    reservationArray, setReservationArray,
    relatedProfileArray, setRelatedProfileArray,
    profileAgeArray, profileEatingArray
}) => {
    const [isOpenNew,setIsOpenNew] = useState(false);
    const [editReservation, setEditReservation] = useState(getEmptyReservation(profileObject.id));
    //
    const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    // 
    const [showHotalInfo, setShowHotelInfo] = useState(false);


    const handleOpenNew = () => {
        setEditReservation(getEmptyReservation(profileObject.id));
        setIsOpenNew(true);
    }

    const handleOpenEdit = (currentReservationId:number) => {
        let found = reservationArray.find(x => x.id === currentReservationId);
        if (found !== undefined) {
            setEditReservation(found);
            setIsOpenNew(true);
        }

    }


    const handleAfterSaveNew = (newReservation:IReservation) => {
        let found = reservationArray.find(x => x.id === newReservation.id);

        if (found === undefined) {
            setReservationArray([
                ...reservationArray,
                newReservation
            ])
            setShowHotelInfo(newReservation.hotel_friday || newReservation.hotel_saturday);
            
        } else {
            setReservationArray([
                ...reservationArray.map(x => x.id === newReservation.id ? newReservation : x) 
            ])
        }

        setEditReservation(newReservation);
        setIsOpenNew(false);
    }


    const handleSaveNew = () => {
        if (editReservation.id < 0) {
            uploadFetch("/reservation/add/",true,editReservation,handleAfterSaveNew,setWasSuccessfullySaved,setIsLoading)
        } else {
            uploadFetch(`/reservation/update/${editReservation.id}`,false,editReservation,handleAfterSaveNew,setWasSuccessfullySaved,setIsLoading)
        }
        
    }


    const getNameFromProfile = (profileId:number | undefined) => {
        let currentObject = relatedProfileArray.find(x => x.id === profileId);
        if (currentObject === undefined) {
            return("Fehler")
        }
        else if (currentObject.User.first_name !== "" && currentObject.User.last_name !== "") {
            return `${currentObject.User.first_name} ${currentObject.User.last_name}`
        }
        else {
            return currentObject.User.username
        }
    }


    const getHotelInfor = (currenReservation:IReservation) => {
        return(
            <>
                <Card sx={{width: 500,mb: 5}}>
                    <CardContent>
                        <Hotel fontSize="large"/>
                        <br/>
                        Bitte verwende folgenden <b><a href="https://studio-ze.de/gut-sarnow/?vorname=Pia%20und%20Daniel&datum=14.%20Juni%202025" target="_blank">Link</a></b>, um dich <b>beim Hotel anzumelden</b>.
                        {currenReservation.hotel_friday && <p>Hinterlege bitte in <b>den Notizen</b>, dass du bereits am <b>Freitag anreisen und ein Zimmer</b> buchen willst.</p>}
                    </CardContent>
                </Card>
            </>
        )
    }


    return(
        <>
            <Dialog open={showHotalInfo}>
                <DialogContent>
                    {getHotelInfor(editReservation)}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShowHotelInfo(false)}>Okay</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={isOpenNew} onClose={() => setIsOpenNew(false)}>
                <DialogContent>
                    <Collapse in={isLoading}>
                        <LinearProgress/>
                    </Collapse>
                    <Collapse in={!wasSuccessfullySaved}>
                        <Alert severity="error">
                            Es ist ein Fehler aufgetretten!
                            <br/>Versuche es später bitte erneut. Bleibt der Fehler bestehen, dann schreibe uns bitte eine <a href="mailto:pia-und-daniel@kmiotek.de">E-Mail </a>.
                        </Alert>
                    </Collapse>
                    <ReservationEdit
                        profileObject={profileObject}
                        editReservation={editReservation}
                        setEditReservation={setEditReservation}
                        relatedProfileArray={relatedProfileArray}
                        setRelatedProfileArray={setRelatedProfileArray}
                        profileAgeArray={profileAgeArray}
                        profileEatingArray={profileEatingArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} variant="outlined" onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                    <Button disabled={isLoading} variant="contained" onClick={handleSaveNew}>Speichern</Button>
                </DialogActions>
            </Dialog>


            <Typography variant="h5">Meine Anmeldung</Typography>
            {(reservationArray.length === 0)
                ? <Box sx={{mt: 3}}>
                    <Typography>Es liegt keine Anmeldung vor!</Typography>
                    <Button sx={{mt: 3}} variant="contained" onClick={handleOpenNew}>Jetzt starten!</Button>
                </Box>
                : reservationArray.map(x =>
                    <>
                        <Button sx={{mt: 5}} onClick={() => handleOpenEdit(x.id)} variant="contained">Anpassen</Button>
                        <Grid sx={{mt: 3}} container>
                            {(x.hotel_saturday || x.hotel_friday) &&
                                <Grid xs={12} >
                                    {getHotelInfor(x)}
                                    <Box sx={{mb: 5}}/>
                                </Grid>
                            }
                            <Grid xs={12}><b>Grundinformationen</b></Grid>
                            <Grid xs={3}>
                                Ersteller
                            </Grid>
                            <Grid xs={9}>
                                {getNameFromProfile(x.Created_By)}
                            </Grid>

                            <Grid xs={3}>
                                Erstellt am
                            </Grid>
                            <Grid xs={9}>
                                {new Date(String(x.created_at)).toLocaleString()}
                            </Grid>

                            <Grid xs={3}>
                                Aktualisiert am
                            </Grid>
                            <Grid xs={9}>
                                {new Date(String(x.updated_at)).toLocaleString()}
                            </Grid>
                        </Grid>

                        <Grid sx={{mt: 3}} container>
                            <Grid xs={12}><b>Freitag</b></Grid>
                            <Grid xs={3}>
                                Teilnahme
                            </Grid>
                            <Grid xs={9}>
                                {x.confirmed_friday ? "Ja" : "Nein"}
                            </Grid>
                            <Grid xs={3}>
                                Hotel
                            </Grid>
                            <Grid xs={9}>
                                {x.hotel_friday ? "Ja" : "Nein"}
                            </Grid>
                        </Grid>

                        <Grid sx={{mt: 3}} container>
                            <Grid xs={12}><b>Samstag</b></Grid>
                            <Grid xs={3}>
                                Teilnahme
                            </Grid>
                            <Grid xs={9}>
                                {x.confirmed_saturday ? "Ja" : "Nein"}
                            </Grid>
                            <Grid xs={3}>
                                Hotel
                            </Grid>
                            <Grid xs={9}>
                                {x.hotel_saturday ? "Ja" : "Nein"}
                            </Grid>
                        </Grid>

                        <Grid sx={{mt: 3}} container>
                            <Grid xs={12}><b>Zugeordnete Personen</b></Grid>
                            {x.ProfileArray.map(y =>
                                <Grid xs={12}>
                                    {getNameFromProfile(y)}
                                </Grid>
                            )}
                        </Grid>

                    </>
                )
            }
        </>
    )
}