import React, { useState } from "react";
import { IProfile } from "../../interfaces/IProfile";
import { IProfileAgeGroup } from "../../interfaces/IProfileAgeGroup";
import { IProfileEatingType } from "../../interfaces/IProfileEatingType";
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@mui/material";
import { PersonEditor } from "./Person/PersonEditor";
import { uploadFetch } from "../../hooks/useFetch";

interface IProps {
    profileObject: IProfile;
    setProfileObject: Function;
    profileAgeArray: IProfileAgeGroup[];
    profileEatingArray: IProfileEatingType[];
}


export const NewAtPortal:React.FC<IProps> = ({profileObject,setProfileObject,profileAgeArray,profileEatingArray}) => {
    const [editProfile,setEditProfile] = useState(profileObject);
    const [editUser,setEditUser] = useState(profileObject.User);
    // 
    const [isNewAtPortal, setIsNewAtPortal] = useState(profileObject.User.first_name === "" || profileObject.User.last_name === "");
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);


    const handleAfterSave = (updatedProfile:IProfile) => {
        setProfileObject(updatedProfile);
        setIsNewAtPortal(false);
    }

    const saveProfile = () => {
        let uploadObject = {
            ...editProfile,
            User: {...editUser} 
        }
        uploadFetch(`/user/profile/${profileObject.id}`,false,uploadObject,handleAfterSave,setWasSuccessfullySaved,setIsLoading)
    }


    return (
        <>
            <Button variant="contained" sx={{float: "right"}} onClick={() => setIsNewAtPortal(true)}>Mein Konto anpassen</Button>
            <Dialog open={isNewAtPortal} onClose={() => {}}>
                <DialogTitle>Herzlich Willkommen!</DialogTitle>
                <DialogContent>
                    <Typography>Es freut uns, dass du den Weg zu unserem Gästeportal gefunden hast!</Typography>
                    <Typography>Hier hast du die Möglichkeit, deine Einladung zur Hochzeit zu bestätigen, weitere Personen anzumelden, Kinderaktivitäten zu buchen und zu einem späteren Zeitpunkt auch Bilder auszutauschen!</Typography>
                    <Box sx={{mt: 5}}/>
                    {(isLoading) && <LinearProgress/>}
                    
                    <Collapse in={!wasSuccessfullySaved}>
                        <Alert severity="error">
                            Es ist ein Fehler aufgetretten!
                            <br/>Versuche es später bitte erneut. Bleibt der Fehler bestehen, dann schreibe uns bitte eine <a href="mailto:pia-und-daniel@kmiotek.de">E-Mail </a>.
                        </Alert>
                    </Collapse>
                    
                    <PersonEditor
                        editProfile={editProfile}
                        setEditProfile={setEditProfile}
                        editUser={editUser}
                        setEditUser={setEditUser}
                        profileAgeArray={profileAgeArray}
                        profileEatingArray={profileEatingArray}
                        disableEMail
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={editUser.first_name === "" || editUser.last_name === "" || isLoading} onClick={saveProfile}>Speichern</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}